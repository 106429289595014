define(function(require) {
    "use strict";

    //#import

    //@implementation

    /**
     * LogTypeConstant
     *
     * @author alexander.claes
     */
    var LogTypeConstant = {};

    /**
     * Log type "undefined"
     * @type {String}
     * @constant
     */
    LogTypeConstant.UNDEFINED = 'undefined';

    /**
     * Log type "ajax_communication"
     * @type {String}
     * @constant
     */
    LogTypeConstant.AJAX_COMMUNICATION = 'ajax_communication';

    /**
     * Log type "ajax_response"
     * @type {String}
     * @constant
     */
    LogTypeConstant.AJAX_RESPONSE = 'ajax_response';


    /**
     * Log type "notification_error"
     * @type {String}
     * @constant
     */
    LogTypeConstant.NOTIFICATION_ERROR = 'notification_error';

    /**
     * Log type "heartbeat"
     * @type {String}
     * @constant
     */
    LogTypeConstant.HEARTBEAT = 'heartbeat';

    return LogTypeConstant;

});
