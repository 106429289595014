define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');
    var _ = require('underscore');
    var ScrollEventOverlay = require('Jvm/ScrollModule/Event/ScrollEventOverlay');
    var BreakpointConstant = require('Jvm/ViewportModule/Constant/BreakpointConstant');
    var BreakpointService = require('Jvm/ViewportModule/Service/BreakpointService');
    var ClientUtility = require('Jvm/CoreModule/Utility/ClientUtility');

    //@implementation

    /**
     * ScrollServiceOverlay
     *
     * @author matthias.rohmer
     *
     * @constructor
     */
    var ScrollServiceOverlay = function() {

        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        /**
         * reference to document selector
         *
         * @type {object}
         */
        var elOverlay = $('.te-overlay__wrapper');

        /**
         * Marker to fire start srolling event
         * @type {Boolean}
         */
        var scrollStarted = false;

        /**
         * Current scroll position
         * @type {Integer}
         */
        var scrollPosition;

        /**
         * Timer to fire stop scrolling event
         */
        var timer;

        /**
         * Deplay time for throttle scrolling
         * @type {float}
         */
        var throttleDelay = 1000 / 4;

        // Singleton constructor
        ScrollServiceOverlay = function ScrollServiceOverlay() {
            return instance;
        };

        /**
         * Init
         */
        function init() {
            saveScrollPosition();
            elOverlay.scroll(_.throttle(fire, throttleDelay));
        }

        /**
         * Update and fire event
         */
        function fire() {
            // Mark and fire start scrolling
            if (!scrollStarted) {
                scrollStarted = true;
                ScrollEventOverlay.trigger(ScrollEventOverlay.SCROLL_START);
            }

            // Clear timer
            if (timer) {
                clearTimeout(timer);
            }

            // Trigger scrolling
            saveScrollPosition();
            ScrollEventOverlay.trigger(ScrollEventOverlay.SCROLL);

            // Mark and fire stop scrolling
            timer = setTimeout(function() {
                scrollStarted = false;
                ScrollEventOverlay.trigger(ScrollEventOverlay.SCROLL_STOP);
            }, 350);
        }

        function saveScrollPosition() {
            ClientUtility.readDom(function() {
                scrollPosition = elOverlay.scrollTop();
            });
        }

        instance.getScrollPosition = function() {
            return scrollPosition;
        };

        instance.goToScrollPosition = function(position) {
            if (position || position === 0) {

                if(position < 0){
                    position = 0;
                }

                elOverlay.scrollTop(position);
            }
        };

        //Call Init
        init.call(this);

        return instance;
    };

    return new ScrollServiceOverlay();
    //@end
});
