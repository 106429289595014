define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');
    var _ = require('underscore');
    var BreakpointService = require('Jvm/ViewportModule/Service/BreakpointService');
    var ResizeService = require('Jvm/ViewportModule/Service/ResizeService');

    //@implementation

    /**
     * LogService
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var LogService = function() {

        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        /**
         * URL of log endpoint, can vary by log type
         */
        var endpointUrl = {
            'default': null
        };

        // Singleton constructor
        LogService = function LogService() {
            return instance;
        };

        /**
         * Get URL of the log endpoint based on the type.
         * @param  {String} type    The log entrie's type (contant).
         * @return {String|null}    URL of the correspondig endpoint.
         */
        function getEndpointUrl(type) {
            var result = null;

            // Is a type given?
            if (type && type.length) {
                // Is there an endpoint for this type?
                if (endpointUrl[type]) {
                    result = endpointUrl[type];
                }
                // Fall back to default endpoint.
                if (!result || !result.length && endpointUrl['default']) {
                    result = endpointUrl['default'];
                }
            }

            return result;
        }

        /**
         * Grab all relevant information about the current environment
         */
        function getEnvironmentData(){
            var result = {};

            // Collect data from current page url
            if(typeof window === 'object' && typeof window.location === 'object'){

                // Define properties, that should be grabed from current url
                var locationProperties = ['protocol', 'hostname', 'port', 'pathname', 'search', 'hash'];

                // Create a temporary a-element to read the location properties
                var loc = document.createElement('a');
                loc.href = window.location.href;

                // Save all relevant location properties
                _.each(locationProperties, function(property){
                    if(typeof loc[property] !== 'undefined'){
                        result['request_' + property] = loc[property];
                    }
                });

                // Clear the a-element
                loc = null;
            }

            // User agent
            if(typeof navigator !== 'undefined' && typeof navigator.userAgent !== 'undefined'){
                result.browser_userAgent = navigator.userAgent ;
            }

            // Viewport information
            result.browser_breakpoint = BreakpointService.getBreakpoint();
            result.browser_viewport_width = ResizeService.getWindowWidth();
            result.browser_viewport_height = ResizeService.getWindowHeight();

            // Cookies enabled?
            result.browser_feature_cookies = navigator.cookieEnabled;

            // Other browser features based on modernizr
            if(typeof Modernizr === 'object') {
                var modernizrProperties = ['localStorage', 'sessionStorage', 'pointerEvents'];

                // Run all modernizr tests
                _.each(modernizrProperties, function(property){
                    var modernizrProperty = property.toLowerCase();
                    if(Modernizr && typeof Modernizr[modernizrProperty] !== 'undefined'){
                        result['browser_feature_' + property] = Modernizr[modernizrProperty];
                    }
                });
            }


            return result;
        }

        /**
         * Set url for log endpoint.
         * @param  {String} url  URL for the endpoint.
         * @param  {String} type A special url for certain log types (contant).
         */
        instance.setEndpointUrl = function(url, type) {
            if (url && url.length) {
                // Set a default type.
                if (typeof type === 'undefined' || !type.length) {
                    type = 'default';
                }
                // Store endpoint's url
                endpointUrl[type] = url;
            }
        };

        /**
         * Log a specific event
         * @param  {String} type           Log entrie's type. (constant)
         * @param  {String} level          Log entrie's level. (constant)
         * @param  {String} message        Message describing the event
         * @param  {String} context        Context of the event's occurence (e.g. view name)
         * @param  {Object} additionalData Various additional data to store with the log entry
         * @param  {Boolean} suppressDefaultData Include or suppress default environment data
         */
        instance.log = function(type, level, message, context, additionalData, suppressDefaultData) {

            // Parametes "type" and "level" are required
            if(type && type.length && level && level.length){

                // Get the endpoint's url correspondig to log entries type
                var url = getEndpointUrl(type);

                if (url && url.length) {

                    // Object containing all data that should be stored in the log
                    var logData = {};

                    // Store all basic log data
                    logData.type = type;
                    logData.level = level;

                    if(message && message.length) {
                        logData.message = message;
                    }

                    if(context && context.length) {
                        logData.context = context;
                    }

                    // Store environment data
                    if(suppressDefaultData !== true){
                        _.extend(logData, getEnvironmentData());
                    }

                    // Store all additional data
                    if(typeof additionalData === 'object'){
                        _.extend(logData, additionalData);
                    }

                    // We can not use AjaxService for this call!
                    // AjaxService requires() LogService to log all errors.
                    // LogService requires() AjaxServer to send log request.
                    // This is a ciruclar dependency. If both AMD modules require each other, there is a conflict.
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: logData
                    });
                }
            }

        };

        return instance;
    };

    return new LogService();
    //@end
});
