import { registerDynamicView } from 'Jvm/CoreModule/Factory/ViewFactory'; 
import FilterCollection from './Collection/FilterCollection';
import FilterSelectionCollection from './Collection/FilterSelectionCollection';
import MetaDataCollection from './Collection/MetaDataCollection';

export function init() {
    const metaDataCollection = new MetaDataCollection();
    const filterCollection = new FilterCollection();
    const filterSelectionCollection = new FilterSelectionCollection();
 
    registerDynamicView('Listing', () => import(/* webpackMode: "eager" */'./View/ListingView'), {
        viewParameters: [metaDataCollection],
    });

    registerDynamicView('ListingSection', () => import('./View/ListingSectionView'), {
        viewParameters: [metaDataCollection]
    });

    registerDynamicView('ListingFilter', () => import('./View/ListingFilterView'), {
        viewParameters: [filterCollection, filterSelectionCollection]
    });

    registerDynamicView('ListingFilterMultiSelect', () => import('./View/ListingFilterMultiSelectView'), {
        viewParameters: [filterCollection]
    });

    registerDynamicView('ListingFilterRadio', () => import('./View/ListingFilterRadioView'), {
        viewParameters: [filterCollection]
    });

    registerDynamicView('ListingFilterRange', () => import('./View/ListingFilterRangeView'), {
        viewParameters: [filterCollection]
    });

    registerDynamicView('ListingLoadMore', () => import('./View/ListingLoadMoreView'), {
        viewParameters: [metaDataCollection, filterSelectionCollection]
    });

    registerDynamicView('ListingResultCount', () => import('./View/ListingResultCountView'), {
        viewParameters: [metaDataCollection]
    });
}
