define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');
    var _ = require('underscore');
    var ModuleScopeConstant = require('Jvm/CoreModule/Constant/ModuleScopeConstant');
    var BreakpointEvent = require('Jvm/ViewportModule/Event/BreakpointEvent');
    var ResizeEvent = require('Jvm/ViewportModule/Event/ResizeEvent');

    //@implementation

    /**
     * ModuleScopeService
     *
     * @author alexander.claes
     *
     * @constructor
     */
    var ModuleScopeService = function() {


        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        /**
         * Breakpoints
         *
         * @private
         * @type {object}
         */
        var breakpoints = {};

        /**
         * Current Breakpoint
         *
         * @private
         * @type {string}
         */
        var currentBreakpoint = '';

        /**
         * Last Breakpoint
         *
         * @private
         * @type {string}
         */
        var lastBreakpoint = '';

        /**
         * DOM Element to indicate the current breakpoint
         *
         * @private
         * @type {object}
         */
        var breakpointIndicator = null;

        // Singleton constructor
        ModuleScopeService = function ModuleScopeService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {

        }

        /**
         * Check if a breakpoint is lower than the current breakpoint
         * @param  {string}  breakpoint The breakpoint that should be tested
         * @return {Boolean}
         */
        instance.getScope = function(element) {
            if (element.hasClass('te-overlay__content')) {
                return ModuleScopeConstant.OVERLAY;
            } else {
                return ModuleScopeConstant.BODY;
            }
        };


        //Call Init
        init.call(this);

        return instance;
    };

    return new ModuleScopeService();
    //@end
});
