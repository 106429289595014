define(function(require) {
    "use strict";

    //#import

    //@implementation

    /**
     * ModuleScopeContant
     *
     * @author alexander.claes
     */
    var ModuleScopeConstant = {};

    ModuleScopeConstant.GLOBAL = 'global';

    ModuleScopeConstant.HEAD = 'head';

    ModuleScopeConstant.BODY = 'body';

    ModuleScopeConstant.OVERLAY = 'overlay';

    return ModuleScopeConstant;

});
