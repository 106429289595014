define(function(require) {
    "use strict";

    //#import

    //@implementation

    /**
     * LogLevelConstant
     *
     * @author alexander.claes
     */
    var LogLevelConstant = {};

    /**
     * Log type "notice"
     * @type {String}
     * @constant
     */
    LogLevelConstant.NOTICE = 'notice';

    /**
     * Log type "warning"
     * @type {String}
     * @constant
     */
    LogLevelConstant.WARNING = 'warning';

    /**
     * Log type "error"
     * @type {String}
     * @constant
     */
    LogLevelConstant.ERROR = 'error';

    return LogLevelConstant;

});
