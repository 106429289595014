define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var _ = require('underscore');

    //@implementation

    /**
     * ScrollEventOverlay
     *
     * @author carina.grabke
     */
    var ScrollEventOverlay = _.extend({}, Backbone.Events);

    /**
     * Fired when window is scrolled
     * @type {String}
     * @constant
     */
    ScrollEventOverlay.SCROLL = 'scrollOverlay:scroll';

    ScrollEventOverlay.SCROLL_START = 'scrollOverlay:start';

    ScrollEventOverlay.SCROLL_STOP = 'scrollOverlay:stop';

    return ScrollEventOverlay;

});
