define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var _ = require('underscore');

    //@implementation

    /**
     * ListingEvent
     *
     * @author alexander.claes
     */
    var ListingEvent = _.extend({}, Backbone.Events);

    /**
     * Fired when the list should be updated
     * @type {String}
     * @constant
     */
    ListingEvent.UPDATE_PRODUCTS = 'listing:update';

    /**
     * Fired when a filter is toggled
     * @type {String}
     * @constant
     */
    ListingEvent.TOGGLE_FILTER = 'filter:toggle';

    /**
     * Fired when a filter is initialized
     * @type {String}
     * @constant
     */
    ListingEvent.INIT_FILTER = 'filter:init';
    ListingEvent.UPDATE_FILTER = 'filter:update';

    /**
     * Stop automatic loading of listing items
     * @type {String}
     * @constant
     */
    ListingEvent.STOP_AUTOLOAD = 'autoload:stop';

    ListingEvent.INIT_FILTER_SELECTION = 'filter:init_filter_selection';


    return ListingEvent;

});
