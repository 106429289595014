define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * ListDataModel
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function ListDataModel() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            $element: null,
            section: null,
            active: false,
            partial: false,
            ready: false,
            offset: null
        };

        /**
         * Set the model's active state true
         */
        instance.activate = function() {
            this.set('active', true);
        };

        /**
         * Set the model's active state false
         */
        instance.deactivate = function() {
            this.set('active', false);
        };

        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return ListDataModel;
    //@end
});
