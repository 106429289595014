// require( './ListingResultCountView');
// require( './ListingFilterMultiSelectView');
// require( './ListingFilterRadioView');
// require( './ListingFilterRangeView');
// require( './ListingLoadMoreView');
// require('./ListingFilterView');

define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');
    var _ = require('underscore');
    var ListingEvent = require('Eis/ListingModule/Event/ListingEvent');
    //var ListDataModel = require('Eis/ListingModule/Model/ListDataModel');
    var AjaxService = require('Jvm/CoreModule/Service/AjaxService');
    var DataConfigUtility = require('Jvm/CoreModule/Utility/DataConfigUtility');
    var CoreEvent = require('Jvm/CoreModule/Event/CoreEvent');
    var ModuleScopeConstant = require('Jvm/CoreModule/Constant/ModuleScopeConstant');
    var ModuleScopeService = require('Jvm/CoreModule/Service/ModuleScopeService');
    var NotificationEvent = require('Eis/NotificationModule/Event/NotificationEvent');
    var ScrollEvent = require('Jvm/ScrollModule/Event/ScrollEvent');
    var ScrollEventOverlay = require('Jvm/ScrollModule/Event/ScrollEventOverlay');
    var ScrollService = require('Jvm/ScrollModule/Service/ScrollService');
    var ScrollServiceOverlay = require('Jvm/ScrollModule/Service/ScrollServiceOverlay');
    var ResizeService = require('Jvm/ViewportModule/Service/ResizeService');
    var ClientUtility = require('Jvm/CoreModule/Utility/ClientUtility');
    var MetaDataModel = require('Eis/ListingModule/Model/MetaDataModel');
    var AjaxParameterModel = require('Eis/ListingModule/Model/AjaxParameterModel');
    var ListCollection = require('Eis/ListingModule/Collection/ListCollection');
    var LoadMoreCollection = require('Eis/ListingModule/Collection/LoadMoreCollection');

    //@implementation

    /**
     *
     * ListingView
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function ListingView(metaDataCollection) {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * URL for Ajax request
         * @type {String}
         */
        instance.ajaxUrl = null;

        instance.initialEmpty = false;

        instance.startSection = 1;

        /**
         * Switch to check if the scrolling has been initialized
         */
        instance.scrollingInitialized = false;

        /**
         * Is browser history api supported
         * @type {Boolean}
         */
        instance.browserHistory = (window.history.pushState) ? true : false;

        /**
         * Default content saves content rendered in page (should be loading indicator)
         * @type {object}
         */
        instance.loaderContent = null;

        instance.filter = null;

        instance.isScrolling = false;

        instance.metaDataModel = null;

        instance.ajaxParameterModel = null;

        instance.isOverlay = false;

        instance.scrollService = null;

        instance.scrollEvent = null;

        instance.loadMoreCollection = null;

        /**
         * Initialize the view
         */
        instance.initialize = function() {
            var self = this;
            // Save persistent reference to the view's main DOM element
            this.$el = this.options.element;
            this.loaderContent = this.$el.find('[data-element-loader]').remove().removeClass('e-hidden');

            // Keep track of reference to "this" across functions
            _.bindAll(this, 'onInitFilterSelection', 'onUpdateProducts', 'onAjaxSuccess', 'onAjaxError', 'onScroll', 'onScrollStop', 'onSectionReadyChange', 'initScrolling', 'initScrollingBuffer', 'destroy');

            this.ajaxUrl = this.$el.data('ajaxUrl');

            if (ModuleScopeService.getScope(this.options.scope) === ModuleScopeConstant.OVERLAY) {
                this.isOverlay = true;
                this.scrollService = ScrollServiceOverlay;
                this.scrollEvent = ScrollEventOverlay;
            } else {
                this.scrollService = ScrollService;
                this.scrollEvent = ScrollEvent;
            }

            this.scrollEvent.on(this.scrollEvent.SCROLL_START, function() {
                self.isScrolling = true;
            });

            this.scrollEvent.on(this.scrollEvent.SCROLL_STOP, function() {
                self.isScrolling = false;
            });

            // deactivate browser history changes if listing in overlay
            if(this.isOverlay) {
                this.browserHistory = false;
            }

            this.ajaxParameterModel = new AjaxParameterModel();
            this.listCollection = new ListCollection();
            this.loadMoreCollection = new LoadMoreCollection();
            this.metaDataModel = new MetaDataModel();

            this.metaDataModel.set('overlay', this.isOverlay);
            this.metaDataModel.set('listCollection', this.listCollection);
            this.metaDataModel.set('loadMoreCollection', this.loadMoreCollection);

            metaDataCollection.add(this.metaDataModel);

            this.startSection = this.$el.data('startSection') || 1;
            this.metaDataModel.set('sectionCount', this.startSection);
            this.metaDataModel.set('currentSection', this.startSection);
            this.ajaxParameterModel.set('section', this.startSection);

            this.initialEmpty = this.$el.data('initialEmpty') || false;
            this.metaDataModel.set('initialEmpty', this.initialEmpty);


            // Store constant ajax parameter
            var additonalAjaxParameter = DataConfigUtility.parseDataString(this.$el.data('ajaxParameter'));
            _.each(additonalAjaxParameter, function(value, key) {
                self.ajaxParameterModel.set(key, value);
            });

            // Set this.filter from external datasrc
            ListingEvent.on(ListingEvent.INIT_FILTER_SELECTION, this.onInitFilterSelection);
            // External call to update products
            ListingEvent.on(ListingEvent.UPDATE_PRODUCTS, this.onUpdateProducts);
            this.metaDataModel.on('change:currentSection', _.throttle(this.onSectionChange, 1000), this);

            // Because of section height caculation for mobile, scrolling related stuff
            // must tak place AFTER all sections are ready and their height is set.
            this.listCollection.on('change:ready', this.onSectionReadyChange);
        };

        instance.onInitFilterSelection = function(filter) {
            if(filter && !this.filter) {
                this.filter = filter;
            }
        };

        /**
         * Initialize all bindings related to the scrolling position
         * Important: This must happen aufter all sections area ready
         */
        instance.initScrolling = function(){
            var last_product_tile_id;

            if(ClientUtility.sessionstorage) {
                last_product_tile_id = sessionStorage.getItem('last-product-tile-id');
                sessionStorage.removeItem('last-product-tile-id');
            }

            // Make sure the start section is in the viewport
            if (this.startSection !== 1 && !last_product_tile_id) {
                ScrollService.goToScrollPosition(this.$el.find('[data-section-count='+this.startSection+']').offset().top, true);
            } else if (last_product_tile_id && $('#' + last_product_tile_id).length > 0) {
                ScrollService.goToScrollPosition($('#' + last_product_tile_id).offset().top, true);
            }

            // Bind to scroll events
            this.scrollEvent.on(this.scrollEvent.SCROLL, _.throttle(this.onScrollStop, 1000));
            this.scrollEvent.on(this.scrollEvent.SCROLL_STOP, this.onScrollStop);

            // Rember that scrolling is now initialized
            this.scrollingInitialized = true;
        };

        /**
         * Buffered call for initScrolling
         * May be triggerd multiple time because there are multiple section on application startup
         */
        instance.initScrollingBuffer = function(){
            // If the scrolling stuff has not been initilized yet
            if(!this.scrollingInitialized){

                // Clear buffer timeout if running
                if(this.initScrollingTimeout){
                    clearTimeout(this.initScrollingTimeout);
                }

                // Trigger initScrolling after a timeout
                var self = this;
                this.initScrollingTimeout = setTimeout(function(){

                    // If all sections are initialized
                    if(self.listCollection.length && self.listCollection.where({ready:true}).length){
                        // Init stuff related to scrolling
                        self.initScrolling();
                    }
                }, 100);
            }
        };

        instance.onUpdateProducts = function(section, reset, filter, load_more_position, is_overlay) {
            reset = reset || false;
            load_more_position = load_more_position || 'bottom';
            is_overlay = is_overlay || false;

            if(this.isOverlay != is_overlay) {
                return;
            }

            // initial clear offset attribute
            this.ajaxParameterModel.unset('offset');

            var self = this,
                new_section,
                LoadMoreModel;

            if (this.ajaxUrl) {

                LoadMoreModel = this.loadMoreCollection.findWhere({
                    'position': load_more_position
                });

                /*if (this.ajaxParameterModel.get('offset') === 0) {
                    this.metaDataModel.set('currentCount', 0);
                    this.ajaxParameterModel.set('count', this.countReset);
                }*/
                var data = {};
                var currentSection = this.listCollection.where({
                    'section': this.metaDataModel.get('currentSection')
                })[0];

                if (typeof section === 'string' || typeof section === 'number') {
                    data = $.extend({}, this.ajaxParameterModel.toJSON(), {'section': section});
                    if (reset) {
                        this.ajaxParameterModel.set('section', 1);
                        this.metaDataModel.set('currentSection', 1);
                        this.metaDataModel.set('sectionCount', 1);
                        this.listCollection.reset();
                        ListingEvent.trigger(ListingEvent.STOP_AUTOLOAD);

                        this.$el.empty().append(this.loaderContent);
                    }
                } else {
                    if (currentSection && currentSection.get('partial')) {
                        data = $.extend({}, this.ajaxParameterModel.toJSON(), {'index': currentSection.get('partial')});
                    } else if(currentSection) {
                        // decide up or down section request
                        if(this.startSection > 1 && (LoadMoreModel && LoadMoreModel.get('position') == 'top') && currentSection.get('section') > 1) {
                            new_section = currentSection.get('section') -1;
                            if(currentSection.get('offset') && 'prev' in currentSection.get('offset')) {
                                this.ajaxParameterModel.set('offset', $.extend({}, currentSection.get('offset')['prev'], {'destination': 'prev'}));
                            }
                        } else {
                            new_section = currentSection.get('section') +1;
                            if(currentSection.get('offset') && 'next' in currentSection.get('offset')) {
                                this.ajaxParameterModel.set('offset', $.extend({}, currentSection.get('offset')['next'], {'destination': 'next'}));
                            }
                        }
                        // check if requested section exists
                        if(!this.listCollection.findWhere({'section': new_section})) {
                            self.ajaxParameterModel.set('section', new_section);
                        } else {
                            // abort request if section exists
                            if(LoadMoreModel) {
                                LoadMoreModel.set('pending', false);
                            }
                            return null;
                        }

                        data = this.ajaxParameterModel.toJSON();
                    }
                }

                if (filter || this.filter) {
                    if (filter) {
                        this.filter = filter;
                    }
                    data = $.extend({}, data, this.filter);
                }

                AjaxService.get(this.ajaxUrl, data, function(response) {
                    self.onAjaxSuccess(response, LoadMoreModel);
                }, this.onAjaxError);
            } else if(!this.ajaxUrl) {
                ListingEvent.trigger(ListingEvent.STOP_AUTOLOAD);
            }
        };

        instance.onAjaxSuccess = function(response, LoadMoreModel) {

            if(LoadMoreModel) {
                LoadMoreModel.set('pending', false);
            }

            var last_scroll_position;

            if (response.success) {

                if(LoadMoreModel) {
                    LoadMoreModel.set('hasMore', response.hasMore);
                }

                var $newList = $(response.html);

                if (response.section && response.section < this.metaDataModel.get('sectionCount') && response.section > this.metaDataModel.get('currentSection')) {
                    $newList = $newList.find('ul.g-listing, ul.g-blogposts');
                    this.$el.find('[data-section-count=' + response.section + ']').empty().append($newList);
                } else if(response.section < this.metaDataModel.get('currentSection')) {
                    if(this.ajaxParameterModel.get('section') === 1 && (LoadMoreModel && LoadMoreModel.get('position') == 'top')) {
                        LoadMoreModel.set('hasMore', false);
                    }
                    if(this.ajaxParameterModel.get('section') >= 1) {
                        last_scroll_position = ScrollService.getScrollPosition();
                        this.metaDataModel.prevSection(1);
                        this.$el.prepend($newList);
                    }
                } else {

                    if (response.partial && this.listCollection.length) {
                        var currentSection = this.listCollection.where({
                            'section': this.metaDataModel.get('currentSection')
                        })[0];
                        // $newList = this.$el.find('.listing-section:last').removeClass('partial').find('ul.g-listing').append(response.html);
                        $newList = this.$el.find('.listing-section:last').find('ul.g-listing').append(response.html);
                        currentSection.set('partial', false);
                    } else {
                        // If request offset was 0 reset the list
                        if (this.ajaxParameterModel.get('section') === 1 && (LoadMoreModel && LoadMoreModel.get('position') == 'bottom')) {
                            this.$el.empty();
                        } else {
                            this.metaDataModel.addSection();
                        }
                        this.$el.append($newList);
                    }
                }

                if(this.metaDataModel.get('initialEmpty')) {
                    this.metaDataModel.set('initialEmpty', false);
                }

                // Update count of total results
                if(typeof response.resultCount !== undefined ){
                    this.metaDataModel.set('resultCount', response.resultCount);
                }

                // Trigger DOM Update event to bind views and events
                CoreEvent.trigger(CoreEvent.DOM_UPDATE, {
                    moduleScope: $newList
                });

                if(typeof last_scroll_position !== 'undefined' && (LoadMoreModel && LoadMoreModel.get('position') == 'top')) {
                    if(!this.isScrolling) {
                        ScrollService.goToScrollPosition(last_scroll_position + $newList[0].offsetHeight);
                    }
                }

            } else {
                ListingEvent.trigger(ListingEvent.STOP_AUTOLOAD);
                NotificationEvent.trigger(NotificationEvent.ERROR, response.notification);
            }
        };

        instance.onAjaxError = function(response) {
            ListingEvent.trigger(ListingEvent.STOP_AUTOLOAD);
            NotificationEvent.trigger(NotificationEvent.ERROR);
        };

        instance.onScroll = function() {
            var self = this;
            var model = this.listCollection.where({
                'section': this.metaDataModel.get('currentSection')
            });

            ClientUtility.readDom(function() {
                if (model.length > 0) {
                    var el = model[0].get('$element'),
                        vpWidth = ResizeService.getWindowWidth(),
                        vpHeight = ResizeService.getWindowHeight(),
                        rect = el[0].getBoundingClientRect();

                    if (rect.bottom < vpHeight / 2) {
                        if(self.listCollection.findWhere({
                            'secion': self.metaDataModel.get('currentSection') +1
                        })) {
                            self.metaDataModel.nextSection(1);
                        }
                    } else if (rect.top > vpHeight / 2) {
                        if(self.listCollection.findWhere({
                            'section': self.metaDataModel.get('currenSection') -1
                        })) {
                            self.metaDataModel.prevSection(1);
                        }
                    }
                }
            });
        };

        instance.onScrollStop = function() {
            var self = this;

            var model = this.listCollection.where({
                'section': this.metaDataModel.get('currentSection')
            });

            if (model.length > 0) {
                var el, vpHeight, rect, next, prev;

                ClientUtility.readDom(function() {
                    el = model[0].get('$element');
                    vpHeight = ResizeService.getWindowHeight();
                    rect = el[0].getBoundingClientRect();
                    next = Math.ceil(rect.bottom * -1 / rect.height);
                    prev = Math.ceil(rect.top / (rect.height + 270));
                });

                ClientUtility.writeDom(function() {
                    if (rect.bottom < 0) {
                        if(self.listCollection.findWhere({
                            'section': self.metaDataModel.get('currentSection') + next
                        })) {
                            self.metaDataModel.nextSection(Math.abs(next));
                        }
                    } else if (rect.top > vpHeight) {
                        if(self.listCollection.findWhere({
                            'section': self.metaDataModel.get('currentSection') - prev
                        })) {
                            self.metaDataModel.prevSection(Math.abs(prev));
                        }
                    }
                });

            }
        };

        instance.onSectionChange = function() {
            var self = this;

            this.listCollection.each(function(model) {
                var section = model.get('section');
                var currentSection = self.metaDataModel.get('currentSection');

                if (
                    (section >= currentSection-1
                        && section <= currentSection+1
                    )
                    || section == 1
                ) {
                    if(!model.get('active')) {
                        model.activate();
                    }
                } else {
                    if(model.get('active')) {
                        model.deactivate();
                    }
                }
            });

            if (this.browserHistory) {
                var url = DataConfigUtility.updateQueryString('section', this.metaDataModel.get('currentSection'), document.location.href);
                window.history.replaceState({'section':this.metaDataModel.get('currentSection')}, document.title, url);
            }
        };

        /**
         * Change:ready callback for listCollection
         */
        instance.onSectionReadyChange = function(){
            this.initScrollingBuffer();
        };

        instance.destroy = function() {
            ListingEvent.off(ListingEvent.INIT_FILTER_SELECTION, this.onInitFilterSelection);
            ListingEvent.off(ListingEvent.UPDATE_PRODUCTS, this.onUpdateProducts);
            this.metaDataModel.off('change:currentSection');
            this.listCollection.off('change:ready');
            this.scrollEvent.off(this.scrollEvent.SCROLL);
            this.scrollEvent.off(this.scrollEvent.SCROLL_STOP);

            metaDataCollection.remove(this.metaDataModel);
        };


        return instance;
    }

    return {
        id: 'Listing',
        object: ListingView
    }; //@end
});
