define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');
    var _ = require('underscore');

    //@implementation

    /**
     * NotificationEvent
     *
     * @author alexander.claes
     */
    var NotificationEvent = _.extend({}, Backbone.Events);

    /**
     * Fire to show a notification
     * @type {String}
     * @constant
     */
    NotificationEvent.SUCCESS = 'notification:success';
    NotificationEvent.ERROR = 'notification:error';
    NotificationEvent.INFO = 'notification:info';
    NotificationEvent.SET_THEME = 'notification:set-theme';


    return NotificationEvent;

});
