define(function(require) {
    "use strict";

    //#import
    var Backbone = require('backbone');

    //@implementation

    /**
     * AjaxParameterModel
     *
     * @author alexander.claes
     *
     * @constructor
     */
    function AjaxParameterModel() {

        /**
         * Instance
         * @type {Object}
         */
        var instance = {};

        /**
         * Default properties
         * @type {Object}
         */
        instance.defaults = {
            section: 1
        };

        /**
         * Set the model's active state true
         */
        instance.nextSection = function() {
            this.set('section', this.get('section') + 1);
        };

        instance.prevSection = function() {
            this.set('section', this.get('section') - 1);
        };

        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return AjaxParameterModel;
    //@end
});
