define(function(require) {
    "use strict";

    //#import
    var $ = require('jquery');
    var _ = require('underscore');
    var LogTypeConstant = require('Jvm/LogModule/Constant/LogTypeConstant');
    var LogLevelConstant = require('Jvm/LogModule/Constant/LogLevelConstant');
    var LogService = require('Jvm/LogModule/Service/LogService');
    var TrackingService = require('Jvm/TrackingModule/Service/TrackingService');
    var DataConfigUtility = require('Jvm/CoreModule/Utility/DataConfigUtility');
    var GoogleTrakkenService = require('Jvm/TrackingModule/Service/GoogleTrakkenService');

    //@implementation

    /**
     * AjaxService
     *
     * @author carina.grabke
     * @author alexander.claes
     *
     * @constructor
     */
    var AjaxService = function() {

        /**
         * Instance
         *
         * @type {object}
         */
        var instance = {};

        // Singleton constructor
        AjaxService = function AjaxService() {
            return instance;
        };

        /**
         * Init
         */
        function init() {

            // Bind global ajax complete to log error messages
            $(document).ajaxComplete(onAjaxComplete);
        }

        /**
         * Global callback for jquery ajax complete
         */
        function onAjaxComplete(e, jqxhr, ajaxOptions, errorThrown){

            // If the calling object adds data to be logged in case of an error
            if(typeof ajaxOptions.logData === 'object'){

                // If the request failed or server send error response
                if(jqxhr.status !== 200 || (jqxhr.responseJSON && !jqxhr.responseJSON.success)){

                    var type = jqxhr.status === 200 ? LogTypeConstant.AJAX_RESPONSE : LogTypeConstant.AJAX_COMMUNICATION;
                    var level = LogLevelConstant.ERROR;
                    var message;
                    var context = ajaxOptions.logData.context || '';
                    var additionalData = {};

                    // Add additional data for ajax errors
                    additionalData.ajax_request_method = ajaxOptions.type;
                    additionalData.ajax_request_URL = ajaxOptions.url;
                    additionalData.ajax_request_data = ajaxOptions.data ? JSON.stringify(ajaxOptions.data) : '';
                    additionalData.ajax_response_status = jqxhr.status;
                    additionalData.ajax_response_statusText = jqxhr.statusText;

                    // Add notification that was displayed the user
                    if(jqxhr.status === 200 && jqxhr.responseJSON && jqxhr.responseJSON.notification){
                        additionalData.ajax_response_notification = JSON.stringify(jqxhr.responseJSON.notification);
                    }

                    // Message can be set by calling object, otherwise default message is used
                    if(ajaxOptions.logData.message && ajaxOptions.logData.message.length){
                        message = ajaxOptions.logData.message;
                    }
                    else {
                        message = 'Unknown error while ajax communication.';
                    }

                    // The calling object may pass various addition data
                    if(typeof ajaxOptions.logData.additional === 'object'){
                        _.extend(additionalData, ajaxOptions.logData.additional);
                    }

                    LogService.log(type, level, message, context, additionalData);
                }
            }

            // Check for tracking information in JSON response
            if(typeof jqxhr.responseJSON === 'object'){
                if(typeof jqxhr.responseJSON.tracking === 'object'){
                    TrackingService.push(jqxhr.responseJSON.tracking);
                }
                else if(typeof jqxhr.responseJSON.tracking === 'string'){
                    var trackingData = DataConfigUtility.parseDataString(jqxhr.responseJSON.tracking);
                    if(trackingData){
                        TrackingService.push(trackingData);
                    }
                }
            }
            // Check for GA4 tracking information in JSON response
            if(typeof jqxhr.responseJSON === 'object'){
                if(Array.isArray(jqxhr.responseJSON.trackingGa4)){
                    for (var i=0; i<jqxhr.responseJSON.trackingGa4.length; i++) {
                        GoogleTrakkenService.push(jqxhr.responseJSON.trackingGa4[i]);
                    }
                }
                else if(typeof jqxhr.responseJSON.trackingGa4 === 'object'){
                    GoogleTrakkenService.push(jqxhr.responseJSON.trackingGa4);
                }
                else if(typeof jqxhr.responseJSON.trackingGa4 === 'string'){
                    var trackingDataGa4 = DataConfigUtility.parseDataString(jqxhr.responseJSON.trackingGa4);
                    if(trackingDataGa4){
                        GoogleTrakkenService.push(trackingDataGa4);
                    }
                }
            }
        }

        /**
         * Check if a url belongs to same hostname as current location
         * @param  {String} url The url to test
         * @return {Boolean}    True, if url matches current hostname
         */
        function isSameHostname(url){

            // Create a temporary a-element to read the location properties.
            var loc = document.createElement('a');
            loc.href = url;
            // Read href and assign again to make sure the URL is fully qualified (important for IE 8)
			var tmp = loc.href;
			loc.href = tmp;

            return loc.hostname === window.location.hostname;
        }

        /**
         * AJAX GET Request
         */
        instance.get = function(url, data, callbackSuccess, callbackError, callbackAlways, withCredentials, logData) {

            if(isSameHostname(url)){
                var xhrFields = {};
                if(withCredentials){
                    xhrFields.withCredentials = true;
                }

                $.ajax({
                    type: "GET",
                    url: url,
                    xhrFields: xhrFields,
                    data: data,
                    beforeSend: function(jqXHR, settings){
                        if(typeof logData === 'object') {
                            settings.logData = logData;
                        }

                        // jqXHR.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                    }
                })
                    .done(callbackSuccess)
                    .fail(callbackError)
                    .always(callbackAlways || function(){});
            }
            else {
                if(typeof callbackError !== 'undefined'){
                    callbackError.call();
                }
            }
        };

        /**
         * AJAX GET Request
         */
        instance.post = function(url, data, callbackSuccess, callbackError, withCredentials, logData) {

            if(isSameHostname(url)){
                var xhrFields = {};
                if(withCredentials){
                    xhrFields.withCredentials = true;
                }

                $.ajax({
                    type: "POST",
                    url: url,
                    xhrFields: xhrFields,
                    data: data,
                    beforeSend: function(jqXHR, settings){
                        if(typeof logData === 'object') {
                            settings.logData = logData;
                        }

                        // jqXHR.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                    }
                })
                    .done(callbackSuccess)
                    .fail(callbackError);
            }
            else {
                if(typeof callbackError !== 'undefined'){
                    callbackError.call();
                }
            }
        };

        /**
         * Check if the browser supports CORS
         */
        instance.supportsCORS = function(){
            var supportsCredentials = 'withCredentials' in new XMLHttpRequest();
            var supportsXDomainRequest = typeof window.XDomainRequest !== 'undefined';
            return supportsCredentials && !supportsXDomainRequest;
        };

        //Call Init
        init.call(this);

        return instance;
    };

    return new AjaxService();
    //@end
});
