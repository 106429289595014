define(function(require) {
   "use strict";

   var Backbone = require('backbone');
   var LoadMoreModel = require('Eis/ListingModule/Model/LoadMoreModel');

   function LoadMoreCollection() {

       var instance = {};

       instance.model = LoadMoreModel;

       var Collection = Backbone.Collection.extend(instance);

       return new Collection;
   };

   return LoadMoreCollection;
});