define(function(require) {
    "use strict";

    var Backbone = require('backbone');

    function LoadMoreModel() {

        var instance = {};

        instance.defaults = {
            $element: null,
            active: false,
            position: 'top',
            hasMore: false,
            pending: false
        };

        instance.activete = function() {
            this.set('active', true);
        };

        instance.deactivete = function() {
            this.set('active', false);
        };

        var Model = Backbone.Model.extend(instance);

        return new Model();
    }

    return LoadMoreModel;

});